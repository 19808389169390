function initScripts() {

	// плавный скролл до якоря
	$(function () {
		$("[href^='#']").click(function () {
			var _href = $(this).attr("href");
			var goto  = $(_href).offset().top
			$("html, body").animate({ scrollTop: goto + "px" });
			return false;
		});
	});
	// маска на телефон
	$(".tel").mask("+7(999)999-9999");

	// fancybox
	$("[data-fancybox]").fancybox({
		fitToView: true,
		clickSlide: 'close',
		smallBtn: false,
		type: 'ajax',
		ajax: {
			type: "POST",
			clickOutside: 'toggleControls'
		}
	});

	// CLIENTS ANIMATION BEFORE
	var clients = document.querySelector('.clients .flex')
	if (clients) {
		clients.innerHTML += clients.innerHTML + clients.innerHTML
		clients.classList.add('moving')
	}


	// BANNER PARALLAX
	function bannerEffect() { 
		var banner = document.querySelector('.banner-img img:not(.svg)')
		if (banner) 
			banner.style.transform = "translate(-50%, " + .2*window.pageYOffset + "px)"
	}
	window.addEventListener("scroll", bannerEffect)

	// IMG PARALLAX
	function ImageEffect() { 
		var img = document.querySelectorAll('.parallax')
		if (img.length > 0) {
			for (let im = 0; im < img.length; im++) {
				img[im].style.transform = "translate(-50%, " + .2*window.pageYOffset + "px)"
			}
		}
	}
	window.addEventListener("scroll", ImageEffect)
	

	// SELECT
	var filter  = document.querySelectorAll('.filter'),
		current = [],
		select  = []
	if (filter) {
		for (let i = 0; i < filter.length; i++) {
			current[i] = filter[i].querySelector('.current')
			if (current[i]) {
				current[i].addEventListener('click', toggleOptionList)
			}
			select[i] = filter[i].querySelector('.select')
			if (select[i]) {
				var options = select[i].querySelectorAll('.option')
				if (options)
					for (let i = 0; i < options.length; i++) {
						options[i].addEventListener('click', chooseOption)
					}
			}
		}
	}
	function toggleOptionList() {
		var optionList = this.nextElementSibling
		if (optionList.classList.contains('opened')) {
			// закрываем
			closeSelect()
		}
		else {
			// открываем
			var ov = document.createElement('div')
				ov.classList.add('filter-overlay')
				document.body.append(ov)
			var overlay = document.querySelector('.filter-overlay')
				overlay.addEventListener('click', closeSelect)
				optionList.classList.add('opened')
				this.classList.add('opened')
		}
	}	
}

function closeSelect() {
	document.querySelector('.filter-overlay').remove()
	document.querySelector('.filter .current').classList.remove('opened')
	document.querySelector('.filter .select').classList.remove('opened')
}

document.addEventListener("DOMContentLoaded", initScripts);